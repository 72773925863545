import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { IconUiModule } from '@x/ecommerce-shop/app/core/shop-ui/components/icon-ui/icon-ui.module';
import { CloseButtonComponent } from './close-button.component';

@NgModule({
  declarations: [CloseButtonComponent],
  imports: [CommonModule, FontAwesomeModule, MatButtonModule, IconUiModule],
  exports: [CloseButtonComponent],
})
export class CloseButtonModule {}
