import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'x-close-button',
  templateUrl: './close-button.component.html',
  styleUrls: ['./close-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CloseButtonComponent {
  @Input() alignment: 'left' | 'right' = 'right';
}
